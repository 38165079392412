.bulleteIcon{
    height: 1.5rem;
    margin-left: 0.5rem;
}
.contentListWrapper{
    margin: 5px 0 0 0;
}
.contentListWrapper>div{
    display: flex;
    align-items: center;
}
.contentListWrapper>div>a{
    padding-left: 0.7rem;
    font-size: 1.2rem;
    color: var(--white);
    font-weight: 100;
    transition: 0.1s ease-in all;
}
.contentListWrapper>div>a:hover{
    color: #c01b5e;
}
