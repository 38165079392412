.footerOuterArea{
    margin-top: 3rem;
    width: 100vw;
}
footer{
    display: block;
    position: fixed;
    width: 100vw;
    bottom: 0rem;
}

.footerWrapper{
    background-color: #4DB5FF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
}

.heartSymbol{
    color: rgb(180, 27, 27);
}



