body{
    background-color: #1F1F38;
}


.headerWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo{
    margin-top: 1rem;
    height: 3rem;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 5px;
}

.PageTitle{
    margin: 1rem;
    color: var(--fontColor);
    font-size: 2.5rem;
}
.contentBodyWrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr) ;
}

@media screen and (max-width: 1000px) {
    .contentBodyWrapper{
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width: 800px) {
    .contentBodyWrapper{
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width: 500px) {
    .contentBodyWrapper{
        grid-template-columns: repeat(1,1fr);
    };

}