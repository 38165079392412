@import url('https://fonts.googleapis.com/css2?family=Belanosima&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Belanosima', sans-serif;
}

:root{
  --fontColor: #4AAAF0;
  --boxBack: #2C2C6C;
  --white:#ffffff;
}
::-webkit-scrollbar {
  display: none;
}
a{
  text-decoration: none;
}


