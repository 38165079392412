.contentWrapperSec{
    background-color: var(--boxBack);
    margin: 10px ;
    padding: 8px 10px ;
    border-radius: 8px;
}
.contentBoxTitle{
    color: var(--fontColor);
    margin: 5px 0px;
    font-size: 1.5rem;
}